<template>
    <form class="c-comment-form" @submit="onFormSubmit">
        <textarea name="comment-text" id="comment-text" v-model="formFieldsData.textContent.value" class="comments-txt" :class="{'has-error':formFieldsData.textContent.hasError}" rows="8" cols="80" :placeholder="$t('archive.entry.commentForm.commentTextLabel')" @focus="formFieldsData.textContent.hasError = false"></textarea>
        <input name="author-name" id="author-name" v-model="formFieldsData.authorName.value" type="text" class="comments-input name" :class="{'has-error':formFieldsData.authorName.hasError}" :placeholder="$t('archive.entry.commentForm.nameLabel')" @focus="formFieldsData.authorName.hasError = false">
        <input name="author-email" id="author-email"  v-model="formFieldsData.authorEmail.value" type="text" class="comments-input mail" :class="{'has-error':formFieldsData.authorEmail.hasError}" :placeholder="$t('archive.entry.commentForm.emailLabel')" @focus="formFieldsData.authorEmail.hasError = false">
        <div class="buttons">
            <button class="btn-white btn-comments" @click.prevent="$emit('closeForm')">{{ $t('archive.entry.commentForm.cancelButtonLabel')}}</button>
            <button class="btn-white btn-comments">{{ $t('archive.entry.commentForm.submitButtonLabel')}}</button>
        </div>
        <div v-if="formSubmitted" class="form-overlay">
            <div v-if="formSent" class="form-sent-message">
                <p v-if="formSentSuccessfully" class="msg success-msg">{{ $t('archive.entry.commentForm.successMsg')}}</p>
                <p v-else-if="formSendingFailed" class="msg error-msg">{{ $t('archive.entry.commentForm.errorMsg')}}</p>
                <p v-else class="loader-spinner"><span>Loading...</span></p>
                <button v-if="formSentSuccessfully || formSendingFailed" @click.prevent="$emit('closeForm')" class="btn-icon">
                    <svg v-if="!isLoading" class="close-form-overlay" width="29" height="29" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29"><path fill="#000" d="M1.7 28.2l-.7-.7 12.9-12.9L1 1.7l.7-.7 12.9 12.9L27.5 1l.7.7-12.9 12.9 12.9 12.9-.7.7-12.9-12.9z"></path></svg>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { submitCommentForm } from '../http/fetchApi';
import { fetchCsrfToken } from '../http/fetchApi';
export default {
    name: 'CommentForm',
    props: {
        entry:null
    },
    data() {
        return {
            formFieldsData: {
                authorName: {
                    value: null,
                    hasError: null
                },
                authorEmail: {
                    value: null,
                    hasError: null
                },
                textContent: {
                    value: null,
                    hasError: null
                },
            },
            formSubmitted: false,
            formSent: false,
            formSentSuccessfully: false,
            formSendingFailed: false,
        }
    },
    emits: [
        'closeForm',
        'formSubmissionSuccess'
    ],
    mounted() {
        //this.fetchCsrfToken();
    },
    methods: {
        validateFormFields() {
            var isValid = true;
            for (const fieldKey in this.formFieldsData) {
                const field = this.formFieldsData[fieldKey];
                if (!(field.value && field.value.length) || (field.value && fieldKey == 'authorEmail' && !this.validateEmail(field.value))) {
                    field.hasError = true;
                    isValid = false;
                } else {
                    field.hasError = false;
                }
            }
            return isValid;
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        async onFormSubmit(e) {
            e.preventDefault();
            if (!this.formSubmitted) {
                this.formSubmitted = true;
                if (this.validateFormFields()) {
                    this.formSent = true;
                    // prepare Form Data...
                    const formData = new FormData();
                    formData.append('atomReferenceCode', this.entry.reference_code);
                    formData.append('atomTitle', this.entry.title);
                    formData.append('archiveEntryUrl', window.location.href);
                    for (const fieldKey in this.formFieldsData) {
                        formData.append(fieldKey, this.formFieldsData[fieldKey].value);
                    }

                    // ... and submit form
                    try {
                        const { data } = await submitCommentForm(formData);
                        this.$emit('formSubmissionSuccess', data);
                        this.formSentSuccessfully = true;
                    } catch(err) {
                        console.log(err); // TypeError: failed to fetch
                        this.formSendingFailed = true;
                    }
                } else {
                    this.formSubmitted = false;
                }
            }
        },
        /*async fetchCsrfToken() {
            try {
                const { data } = await fetchCsrfToken();
                //this.token = data.value;
                this.formSubmitData[data.name] = data.value;
            } catch(err) {
                console.log(err); // TypeError: failed to fetch
            }
        },*/
    }
}
</script>
