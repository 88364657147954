<template>
    <div class="v-archive-entry">
        <button @click.prevent="onCloseButtonClicked" class="close-btn btn-icon">
            <svg class="d-none d-md-block" width="35" height="35" xmlns="http://www.w3.org/2000/svg"><g stroke="#FFF" stroke-width="1.357" fill="none" fill-rule="evenodd"><path d="M.914.48L34.52 34.087M34.52.48L.915 34.087"/></g></svg>
            <svg class="d-block d-md-none" width="29" height="29" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29"><path fill="#fff" d="M1.7 28.2l-.7-.7 12.9-12.9L1 1.7l.7-.7 12.9 12.9L27.5 1l.7.7-12.9 12.9 12.9 12.9-.7.7-12.9-12.9z"/></svg>
        </button>

        <template v-if="currentIndex >= 0 && $globalData.storedArchiveEntries.length">
            <router-link v-if="currentIndex > 0" class="prev-btn btn-icon d-none d-md-block" :to="{ name: 'ArchiveEntry', params: { locale: $route.params.locale, slug: $globalData.storedArchiveEntries[(currentIndex-1)].slug, index:(currentIndex-1) } }" replace><svg width="20" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M18.91.424L1.594 17.79 18.91 35.581" stroke="#FFF" stroke-width="1.357" fill="none" fill-rule="evenodd"/></svg></router-link>
            <router-link v-if="currentIndex < $globalData.storedArchiveEntries.length-1" class="next-btn btn-icon d-none d-md-block" :to="{ name: 'ArchiveEntry', params: { locale: $route.params.locale, slug: $globalData.storedArchiveEntries[(currentIndex+1)].slug, index:(currentIndex+1) } }" replace><svg width="19" height="36" xmlns="http://www.w3.org/2000/svg"><path d="M.734.424L18.048 17.79.734 35.581" stroke="#FFF" stroke-width="1.357" fill="none" fill-rule="evenodd"/></svg></router-link>
        </template>

        <div v-if="entryLoaded" class="container-fluid">
            <div v-if="entry && Object.keys(entry).length" class="row">
                <div class="col-12 col-md-8 wrapper-preview">
                    <div class="wrapper-preview-img" v-if="entry.digital_object && (entry.digital_object.media_type == 'Bild' || entry.digital_object.media_type == 'Image')"  :style="{ 'background-image': 'url(' + entry.digital_object.url + ')' }">
                        <img ref="previewImg" class="preview-img img-fluid img" :src="entry.digital_object.url" :alt="entry.digital_object.filename" :style="{ 'visibility': 'hidden' }">
                    </div>
                    <div class="wrapper-preview-pdf" v-if="entry.digital_object && entry.digital_object.media_type == 'Text'" :style="{ 'background-image': 'url(' + entry.digital_object.reference_url + ')' }">
                        <svg class="img-icon icon-pdf" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 39.76 46.92" xml:space="preserve"><path d="M30.01 9.66V0H0v37.26h9.74v9.66h30.01V9.66h-9.74zM3.8 33.46V3.8h22.41v5.85H9.74v23.8H3.8zm32.16 9.65H13.54V13.46h22.41v29.65z"/></svg>
                        <img ref="previewImg" class="preview-img img-fluid pdf" :src="entry.digital_object.reference_url" :alt="entry.digital_object.filename" :style="{ 'visibility': 'hidden' }">
                    </div>
                    <div class="wrapper-preview-audio" v-if="entry.digital_object && (entry.digital_object.media_type == 'Audio/Ton-' || entry.digital_object.media_type == 'Audio')">
                        <svg class="img-icon icon-audio" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 54.91 44.4" xml:space="preserve"><path d="M0 14.8h4v14.8H0zM9.38 7.4h4V37h-4zM19.76 0h4v44.4h-4zM30.14 14.8h4v14.8h-4zM40.53 7.4h4V37h-4zM50.91 0h4v44.4h-4z"/></svg>
                        <audio-player :poster="entry.digital_object.thumbnail_url" :src-mpeg="entry.digital_object.reference_url"  />
                    </div>
                    <div class="wrapper-preview-video" v-if="entry.digital_object && entry.digital_object.media_type == 'Video'">
                        <svg v-if="!videoPlay" class="img-icon icon-video" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 36.76 46.63" xml:space="preserve"><path d="M32.76 0v4.12h-4.24V0H8.24v4.12H4V0H0v46.63h4v-4.12h4.24v4.12h20.27v-4.12h4.24v4.12h4V0h-3.99zm0 8.12v7.47h-4.24V8.12h4.24zm-4.24 11.46h4.24v7.47h-4.24v-7.47zM8.24 27.05H4v-7.47h4.24v7.47zm0-18.93v7.47H4V8.12h4.24zM4 38.51v-7.47h4.24v7.47H4zm24.52 0v-7.47h4.24v7.47h-4.24z"/></svg>
                        <video-player :poster="entry.digital_object.thumbnail_url" :src-mp4="entry.digital_object.reference_url"  @click="videoPlay =!videoPlay"/>
                    </div>
                    <div class="wrapper-bottom d-flex justify-content-between align-items-end">
                        <div class="comments-wrapper">
                            <button class="comment-button btn-icon" @click="showCommentForm =!showCommentForm" v-if="!showCommentForm">
                                <p>{{ $t('archive.entry.commentForm.showCommentFormButtonLabel')}}</p>
                            </button>
                            <comment-form v-if="showCommentForm" :entry="entry" @closeForm="showCommentForm = false" @formSubmissionSuccess="onCommentFormSubmitSuccess($event)" />
                        </div>
                        <div class="download-share d-none d-md-flex align-items-end flex-column">
                            <div>
                                <a :href="entry.digital_object.url" target="_blank" v-if="entry.digital_object">
                                    <h2>Download</h2>
                                </a>
                            </div>
                            <div class="share d-flex" @mouseover="hover = true" @mouseleave="hover = false">
                                <a href="#" class="share-button" v-if="!hover">
                                    <h2>Share</h2>
                                </a>
                                <div v-if="hover" @mouseover="hover = true" @mouseleave="hover = false" class="share-content d-flex">
                                    <a ref="buttonFB" @click="shareFB()" class="btn btn-default social_share" data-type="fb" :data-title="entry.title" :data-text="entry.scope_and_content||''">
                                        <h2>Facebook</h2>
                                    </a>
                                    <a ref="buttonEmail" @click="shareMail()" class="btn btn-default social_share" data-type="email" :data-title="entry.title" :data-text="entry.scope_and_content||''">
                                        <h2>Mail</h2>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-4 wrapper-text">
                    <div class="intro">
                        <h1>{{ entry.title }}</h1>
                        <p v-if="entry.scope_and_content">{{ entry.scope_and_content }}</p>
                    </div>
                    <div class="info">
                        <template v-if="entry.name_access_points && entry.name_access_points.length">
                            <h2>{{ $t('archive.entry.persons')}}</h2>
                            <div class="p-wrapper">
                                <template v-for="person in entry.name_access_points" :key="person.id + 1000">
                                    <router-link class="archiv-link" :to="{
                                        name: 'ArchiveIndex',
                                        params: {
                                            locale: $route.params.locale,
                                        },
                                        query: {
                                            'name': person.name
                                        }
                                    }">{{ person.name }}<br></router-link>
                                </template>
                            </div>
                        </template>

                        <template v-if="entry.dates">
                            <h2>{{ $t('archive.entry.term')}}</h2>
                            <template v-for="date in entry.dates" :key="date.id">
                                <p>{{getTermInYears(date.start_date, date.end_date)}}</p>
                            </template>
                        </template>

                        <template v-if="entry.subject_access_points && entry.subject_access_points.length">
                            <h2>{{ $t('archive.entry.subjects')}}</h2>
                            <p>
                            <template v-for="subject in entry.subject_access_points" :key="subject.id">
                                <router-link class="archiv-link" :to="{
                                    name: 'ArchiveIndex',
                                    params: {
                                        locale: $route.params.locale,
                                    },
                                    query: {
                                        'subject': subject.name
                                    }
                                }">{{ subject.name }}<br></router-link>
                            </template>
                            </p>
                        </template>

                        <template v-if="entry.genre_access_points && entry.genre_access_points.length">
                            <h2>{{ $t('archive.entry.genres')}}</h2>
                            <p>
                                <template v-for="genre in entry.genre_access_points" :key="genre.id">
                                    <router-link class="archiv-link" :to="{
                                        name: 'ArchiveIndex',
                                        params: {
                                            locale: $route.params.locale,
                                        },
                                        query: {
                                            'genre': genre.name
                                        }
                                    }">{{ genre.name }}<br></router-link>
                                </template>
                            </p>
                        </template>

                        <template v-if="entry.repository">
                            <h2>{{ $t('archive.entry.archive')}}</h2>
                            <div class="repository-with-info" v-if="entry.repository.contact">
                                <p>
                                    <a href="#" class="archiv-link" @click="showRepositoryContact = !showRepositoryContact">{{entry.repository.name }}</a>
                                    <transition name="mini-text-dropdown">
                                        <div v-if="showRepositoryContact" class="repository-contact-data">
                                            <span class="d-block" v-if="entry.repository.contact.street_address">{{entry.repository.contact.street_address}}</span>
                                            <span v-if="entry.repository.contact.country">{{entry.repository.contact.country}}-</span><span v-if="entry.repository.contact.postal_code">{{entry.repository.contact.postal_code}}</span> <span v-if="entry.repository.contact.city">{{entry.repository.contact.city}}</span>
                                            <span class="d-block" v-if="entry.repository.contact.contact_person">{{$t('archive.entry.repository.contactPerson')}}: {{entry.repository.contact.contact_person}}</span>
                                            <span class="d-flex" v-if="entry.repository.contact.email"><a :href="'mailto:'+entry.repository.contact.email" class="block-no-wrap archiv-link mr-auto">{{entry.repository.contact.email}}</a></span>
                                            <span class="d-block" v-if="entry.repository.contact.phone">{{entry.repository.contact.phone}}</span>
                                            <span class="d-flex" v-if="entry.repository.contact.website"><a :href="entry.repository.contact.website" target="_blank" class="block-no-wrap archiv-link mr-auto">{{entry.repository.contact.website.replace('https://', '').replace('http://', '')}}</a></span>
                                        </div>
                                    </transition>
                                </p>
                            </div>
                            <p v-else>{{entry.repository.name }}</p>
                        </template>

                        <template v-if="entry.stock">
                            <h2>{{ $t('archive.entry.stock')}}</h2>
                            <p><router-link class="archiv-link" :to="{ name: 'ArchiveIndex', params: { locale: $route.params.locale, }, query: { 'search': stockSearchTerm } }">{{ entry.stock.name }}</router-link></p>
                        </template>

                        <!--<template v-if="entry.creators && entry.creators.length">
                            <h2>{{ $t('archive.entry.creators')}}</h2>
                            <div class="p-wrapper">
                                <template v-for="creator in entry.creators" :key="creator.id + 1000">
                                    <p>{{ creator.authotized_form_of_name }}</p>
                                </template>
                            </div>
                        </template>-->

                        <template v-if="entry.dossier">
                            <h2>{{ $t('archive.entry.dossier')}}</h2>
                            <p><router-link class="archiv-link" :to="{ name: 'ArchiveIndex', params: { locale: $route.params.locale, }, query: { 'search': dossierSearchTerm } }">{{ entry.dossier.name }}</router-link></p>
                        </template>

                        <template v-if="entry.extent_and_medium">
                            <h2>{{ $t('archive.entry.scopeAndMedium')}}</h2>
                            <p>{{ entry.extent_and_medium }}</p>
                        </template>

                        <template v-if="entry.dates">
                            <h2>{{ $t('archive.entry.date')}}</h2>
                            <div class="p-wrapper">
                                <template v-for="date in entry.dates" :key="date.id">
                                    <p>{{ getFormatedStartDate(date) }}</p>
                                </template>
                            </div>
                        </template>

                        <template v-if="entry.reference_code">
                            <h2>{{ $t('archive.entry.signature')}}</h2>
                            <p>{{entry.reference_code}}</p>
                        </template>
                    </div>

                    <div class="comments">
                        <h2>{{ $t('archive.entry.comments')}}</h2>
                        <template v-for="(comment, index) in entry.comments" :key="'comment-'+index">
                            <div v-if="comment.textContent" >
                                <h4 class="text-uppercase">{{comment.title}}</h4>
                                <p v-html="nl2br(comment.textContent)"></p>
                            </div>
                        </template>
                        <p v-if="!entry.comments.length">{{ $t('archive.entry.noComments')}}</p>
                    </div>

                    <div class="contact">
                        <template v-if="entry.alternative_identifiers">
                            <h2>{{ $t('archive.entry.signature-old')}}</h2>
                            <div class="p-wrapper">
                                <template v-for="signatureOld in entry.alternative_identifiers" :key="signatureOld.id">
                                    <p>{{ signatureOld.identifier }}</p>
                                </template>
                            </div>
                        </template>

                        <template v-if="entry.stock && entry.stock.access_conditions">
                            <h2 v-html="$t('archive.entry.terms-of-use')"></h2>
                            <p>{{entry.stock.access_conditions}}</p>
                        </template>
                    </div>

                </div>
            </div>
            <default-entry-not-found v-else />
        </div>
        <p v-else class="loader-spinner"><span>Loading...</span></p>
    </div>
</template>

<script>
import JSShare from "js-share";
import { fetchArchiveEntryBySlug } from '../http/fetchApi';
import VideoPlayer from '@/components/VideoPlayer.vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import CommentForm from '@/components/CommentForm.vue';
import DefaultEntryNotFound from '@/components/DefaultEntryNotFound.vue';

export default {
    name: 'ArchiveEntry',
    components: {
        VideoPlayer,
        AudioPlayer,
        CommentForm,
        DefaultEntryNotFound
    },
    props: {
        slug: String,
        index: {
            type: [String, Number],
            default: -1
        }
    },
    data() {
        return {
            entry:{},
            hover: false,
            showCommentForm: false,
            entryLoaded: false,
            videoPlay: false,
            fromRoute: {},
            currentIndex: -1,
            showRepositoryContact: false,
        }
    },
    computed: {
        stockSearchTerm() {
            let term = '';
            if (typeof this.entry.reference_code !== 'undefined') {
                term = this.entry.reference_code.split('-').splice(0,2).join('-');
            }
            return term;
        },
        dossierSearchTerm() {
            let term = '';
            if (typeof this.entry.reference_code !== 'undefined') {
                term = this.entry.reference_code.split('-').splice(0,3).join('-');
            }
            return term;
        }
    },
    mounted() {
        this.currentIndex = parseInt(this.index);
        this.fetchEntry();
    },
    updated() {
        if (this.$refs.previewImg) {
            this.initAspectRatioImg(this.$refs.previewImg);
        }
    },
    methods: {
        getTermInYears(startDateStr, endDateStr) {
            let term = startDateStr.split('-')[0];
            if (typeof endDateStr !== 'undefined') {
                const endYear = endDateStr.split('-')[0];
                if (endYear !== term) {
                    term += '–'+endYear;
                }
            }
            return term;
        },
        getFormatedEntryDate(dateStr) {
            return dateStr.replace(/-00/g, '');
        },
        getFormatedStartDate(dateObj) {
            if (typeof dateObj.date !== 'undefined' && dateObj.date.includes('-') && typeof dateObj.start_date !== 'undefined') {
                return this.getFormatedEntryDate(dateObj.start_date);
            } else {
                return dateObj.date;
            }
        },
        shareFB(){
            JSShare.options.url = window.location;
            JSShare.go(this.$refs.buttonFB);
        },
        shareMail(){
            JSShare.options.url = window.location;
            JSShare.go(this.$refs.buttonEmail);
        },
        async fetchEntry() {
            try {
                const { data } = await fetchArchiveEntryBySlug(this.slug);
                this.entry = data;
                this.entryLoaded = true;

                // set header data
                const headerData = {
                    title: 'F+F 1971 - '+this.entry.title,
                };
                if (this.entry.scope_and_content && this.entry.scope_and_content.length) {
                    headerData.description = this.entry.scope_and_content;
                }
                this.$globalData.setHeaderData(headerData);
            } catch(err) {
                console.log(err); // TypeError: failed to fetch
                this.entryLoaded = true;
            }
        },
        onCloseButtonClicked() {
            if (this.currentIndex >= 0 || this.fromRoute.name || false) {
                this.$router.go(-1);
            } else {
                this.$router.push({ name: 'ArchiveIndex', params: { locale: this.$route.params.locale} });
            }
        },
        initAspectRatioImg() {
            const previewImg = this.$refs.previewImg;
            const previewImgWrapper = previewImg.parentNode;
            let linkClass = 'default-img';
            previewImg.addEventListener("load", function() {
                const imgWidth = previewImg.naturalWidth;
                const imgHeight = previewImg.naturalHeight;

                let imageRatio = imgWidth > imgHeight ? 'landscape' : (imgHeight > imgWidth ? 'portrait' : 'square');
                previewImg.classList.add("image-" + imageRatio)
                previewImgWrapper.classList.add(imageRatio)
            });
        },
        nl2br(str) {
            if (str && typeof str == 'string') {
                return str.replace("\n", '<br>');
            }
            return '';
        },
        onCommentFormSubmitSuccess(newComment) {
            this.entry.comments.unshift(newComment);
            const that = this;
            setTimeout(function() {
                that.showCommentForm = false;
            }, 5000)
        }
    },
    beforeRouteEnter(to, from, next) {
        //store previous route
        next(vm => {
            vm.fromRoute = from
        })
    },
}
</script>
